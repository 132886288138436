<script>
  export let visible = false,
    onEscape = null,
    onClick = null,
    style = "grey";

  function handleKeyPress(event) {
    if (!visible) return;
    // Escape
    if (event.which === 27 && onEscape) {
      event.preventDefault();
      onEscape(event);
    }
  }

  function handleClick(event) {
    if (onClick) onClick(event);
  }
</script>

<svelte:window on:keyup={handleKeyPress} />

<section
  class="overlay {style}"
  class:visible
  tabindex="-1"
  role="dialog"
  aria-labelledby="modal-label"
  aria-hidden={visible ? "false" : "true"}
  on:click|self={onClick}
>
  <slot />
</section>

<style>
  @import "../styles/variables.css";

  .overlay {
    /* Maintain a fixed position */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    /* Center the content */
    display: flex;
    align-items: center;
    justify-content: center;

    /* Initially hidden */
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transition: visibility 0s linear 500ms, z-index 0s linear 500ms,
      opacity 500ms;
  }

  .overlay.grey {
    /* Styling */
    background: rgba(0, 0, 0, 0.5);
  }

  .overlay.primary {
    color: white;
    background: var(--primary);
  }

  .overlay.visible {
    visibility: visible;
    opacity: 1;
    z-index: var(--overlay-bg-layer);
    pointer-events: auto;
    transition: visibility 0s linear 0s, z-index 0s linear 0s, opacity 500ms;
  }
</style>
